import React, { useEffect, useRef } from "react";
import { Fragment, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import Avatar from "react-avatar-edit";
import Tooltip from "../Small Components/tooltip";
import { XMarkIcon, ArrowPathRoundedSquareIcon, ChevronLeftIcon, ChevronRightIcon, TrashIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";

import "../OperationsDashboard/Operations.css";

import Papa from "papaparse";
import { PaperAirplaneIcon, LinkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import moment from "moment";
import Tag from "../Tag/Tag";
import IdCardAll from "../All ID Card/IdCardAll";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Done from "../Alerts/Done";
import NotFound from "../Alerts/NotFound";
import CustomCertificate from "../Certificate/CustomCertificate";
import { toast, Toaster } from "sonner";
import Skeleton from "react-loading-skeleton";

var userEmail;
var JSToken;
var accessToken;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};

const Ticket = () => {

  const { eventID } = useParams();
  const [ticketDetail, setTicketDetail] = useState(null);
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [chatThread, setChatThread] = useState(null);
  const [submitDone, setSubmitDone] = useState(false);
  const [onFilters, setOnFilters] = useState(false);
  const [apiFilters, setApiFilters] = useState('["ticket_status","is","set"]');
  const [open, setOpen] = useState(false);
  const [onTicket, setOnTicket] = useState(null);
  const [allEvents, setAllEvents] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [ticketUpdateSuc, setTicketUpdateSuc] = useState(false);
  const [ticketFiles, setTicketFiles] = useState(null);
  const [ticketAccount, setTicketAccount] = useState()
  const [isNotFoundName, setIsNotFoundName] = useState(false);
  const toScroll = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setpageNumber] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    setLoadingSkeleton(true)
    const geteventdata = async () => {
      const result = await axios
        .get(
          `https://dash.bestdiplomats.org/api/resource/Events?fields=["*"]`,
          config
        )
        .then((res) => {
          
          setAllEvents(res.data.data);
          setLoadingSkeleton(false)
          
          if(res.data.data.length==[]){
            toast.error('No Data Found')
            setLoadingSkeleton(true)
            setIsNotFoundName(true);
          }
        })
        .catch((e) => {
          console.log("ticket errrrr", e);
          if (e.code == "ERR_NETWORK") {
            setTicketAccount(0)
            toast.error('No Data Found')
            setLoadingSkeleton(true)
            setIsNotFoundName(true);
           
          }
        });
    };
    geteventdata();
  }, [apiFilters, ticketUpdateSuc]);
  const scrollToTop = () => {
    tableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const handleClick = (number) => {
    setCurrentPage(number);
    scroll();
    scrollToTop()
  };
  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handlePaginationClick = (event) => {
    if (event == "Previous") {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        scroll();
        scrollToTop()
      }
    } else if (event == "Next") {
      if (currentPage < pageNumbers.length) {
        setCurrentPage(currentPage + 1);
        scroll();
        scrollToTop()
      }
    }
  };
  useEffect(() => {
    
    const geteventdata = async () => {
      const result = await axios
        .get(
          `https://dash.bestdiplomats.org/api/resource/Support Ticket?fields=["*"]&filters=[${apiFilters}]&limit_page_length=2000`,
          config
        )
        .then((res) => {
          setOnTicket(res.data.data);
          setTicketAccount(res.data.data.length)
          setpageNumber([]);
            for (
              let i = 1;
              i <= Math.ceil(res.data.data.length / 60);
              i++
            ) {
              setpageNumber((prevState) => [...prevState, i]);
              // console.log(pageNumbers);
            }
          // console.log(">>>>> tic", res.data.data);
        })
        .catch((e) => {
          console.log("ticket errrrr", e);
          toast.error("Failed to load Support Tickets")
        });
    };
    geteventdata();
  }, [apiFilters, ticketUpdateSuc]);
  const handleViewDetails = (person) => {
    setTicketDetail(person);
    // console.log(person);
    setOpen(true);
  };
const [refresh, setRefresh] = useState(false)
const [refreshLoading, setRefreshLoading] = useState(false)
  useEffect(() => {
    const getticketdata = async () => {
      setRefreshLoading(true)
      const result = await axios
        .get(
          `https://dash.bestdiplomats.org/api/resource/Support Ticket Comment?parent=Support Ticket&fields=["*"]&filters=[["parent","=","${ticketDetail?.name}"]]&order_by=creation asc&limit_page_length=999`,
          config
        )
        .then((res) => {
          setChatThread(res.data.data);
          setRefreshLoading(false)
        })
        .catch((e) => {
          console.log("ticket errrrr", e);
          toast.error("Failed to load comments")
        });
    };

    getticketdata();
  }, [ticketDetail, isSuccess,refresh]);
  const [flightDocument, setFlightDocument] = useState(false)
  const [loaderFlightFile, setLoaderFlightFile] = useState(false)
  const [uploadFileResponse, setUploadFileResponse] = useState(null)
  function uploadFlightDocument(e) {
    setFlightDocument(e.target.files[0].name);
    const fileFlight = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileFlight);
    formData.append("is_private", 1);
  
    const uploadFlightApi = async () => {
      try {
        setLoaderFlightFile(true);
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then(async (responseFlight) => {
            setSubmitDone(true)
            await setUploadFileResponse(responseFlight.data);
            setTimeout(() => {
              
              formik.submitForm();
            }, 2000);
            
          
      
          
          });
        setLoaderFlightFile(false);
      } catch (e) {
        console.log(e);
      }
    };

    uploadFlightApi();
  }
  const applyFilters = useFormik({
    initialValues: {
      delegate_first_name: "",
      delegate_last_name: "",
      delegate_email: "",
      ticket_status: "",
      title: "",
      issue_category: "",
      event: "",
    },
    onSubmit: (values) => {
      setApiFilters('["ticket_status","is","set"]');
      if (values.delegate_first_name) {
        if (values.delegate_first_name.length > 1) {
          let delegate_filter_first_name = "%";
          for (let member in values.delegate_first_name) {
            delegate_filter_first_name +=
              values.delegate_first_name[member] + "%";
          }
          setApiFilters((prevState) => [
            prevState +
              `,["delegate_first_name","like","${delegate_filter_first_name}"]`,
          ]);
        }
      }
      if (values.delegate_last_name) {
        if (values.delegate_last_name.length > 1) {
          let delegate_filter_last_name = "%";
          for (let member in values.delegate_last_name) {
            delegate_filter_last_name +=
              values.delegate_last_name[member] + "%";
          }
          setApiFilters((prevState) => [
            prevState +
              `,["delegate_last_name","like","${delegate_filter_last_name}"]`,
          ]);
        }
      }
      if (values.delegate_email) {
        if (values.delegate_email.length > 1) {
          let delegate_email_filter = "%";
          for (let member in values.delegate_email) {
            delegate_email_filter += values.delegate_email[member] + "%";
          }
          setApiFilters((prevState) => [
            prevState + `,["delegate_email","like","${delegate_email_filter}"]`,
          ]);
        }
      }
      if (values.title) {
        if (values.title.length > 1) {
          let delegate_title_filter = "%";
          for (let member in values.title) {
            delegate_title_filter += values.title[member] + "%";
          }
          setApiFilters((prevState) => [
            prevState + `,["title","like","${delegate_title_filter}"]`,
          ]);
        }
      }
      // if (values.event) {
      //   if (values.event.length > 1) {
      //     let delegate_event_filter = "%";
      //     for (let member in values.event) {
      //       delegate_event_filter += values.event[member] + "%";
      //     }
      //     setApiFilters((prevState) => [
      //       prevState + `,["event","like","${delegate_event_filter}"]`,
      //     ]);
      //   }
      // }
      if (values.ticket_status) {
        if (values.ticket_status.length > 1) {
          setApiFilters((prevState) => [
            prevState + `,["ticket_status","=","${values.ticket_status}"]`,
          ]);
        }
      }
      if (values.event) {
        if (values.event.length > 1) {
          setApiFilters((prevState) => [
            prevState + `,["event","=","${values.event}"]`,
          ]);
        }
      }
      if (values.issue_category) {
        if (values.issue_category.length > 1) {
          setApiFilters((prevState) => [
            prevState + `,["issue_category","=","${values.issue_category}"]`,
          ]);
        }
      }
      setOnFilters(false);
    },
    enableReinitialize: true,
  });
  const scrollToBottom = () => {
    document.getElementById("toscroll").scrollTo({
      top: document.getElementById("toscroll").scrollHeight,
      behavior: "smooth",
    });
  };

  const formik = useFormik({
    initialValues: {
      content:""
    },
    onSubmit: async (values) => {
      
      // console.log(">>Values", values, "state:", uploadFileResponse);
      if (
        values.content !== "" &&
        values.content !== null &&
        values.content !== " "
      ) {
        var jsontoPass = {
          parent: ticketDetail?.name,
          parentfield: "thread",
          parenttype: "Support Ticket",
          type: "Comment",
          content: values?.content,
          author: localStorage.user_email,
        };
        const fetchChatData = async (jsontoPass) => {
          try {
            setSubmitDone(true);
            await axios
              .post(
                `${window.$domain}/api/resource/Support Ticket Comment`,
                jsontoPass,config
              )
              .then((res) => {
                // console.log("edit",res);
                setIsSuccess(!isSuccess);
                setTimeout(() => {
                  setSubmitDone(false);
                  scrollToBottom();
                }, 500);
              });
          } catch (e) {
            console.log(e);
          }
        };

        fetchChatData(jsontoPass);
      }else if(uploadFileResponse !== null && uploadFileResponse !== ""){
        var jsontoPass = {
          parent: ticketDetail?.name,
          parentfield: "thread",
          parenttype: "Support Ticket",
          type: "File",
          attachment:uploadFileResponse?.message.file_url,
          author: localStorage.user_email,
          content: "Sent an attachment"
        };
        const fetchChatData = async (jsontoPass) => {
          try {
            setSubmitDone(true);
            await axios
              .post(
                `${window.$domain}/api/resource/Support Ticket Comment`,
                jsontoPass,config
              )
              .then((res) => {
                // console.log("edit",res);
                setIsSuccess(!isSuccess);
                setUploadFileResponse(null)
                setSubmitDone(false);
                setTimeout(() => {
                  setSubmitDone(false);
              
                  scrollToBottom();
                }, 1000);
              });
          } catch (e) {
            console.log(e);
          }
        };

        await fetchChatData(jsontoPass);
       
      }
    },

    enableReinitialize: true,
  });

  const categoryUpdate = useFormik({
    initialValues: {
      issue_category: ticketDetail?.issue_category,
      ticket_status: ticketDetail?.ticket_status,
    },
    onSubmit: (values) => {
      var ticketIssuePass = {
        issue_category: values.issue_category,
        ticket_status: values.ticket_status,
      };

      const fetchcategory = async (ticketIssuePass) => {
        try {
          await axios
            .put(
              `${window.$domain}/api/resource/Support Ticket/${ticketDetail?.name}`,
              ticketIssuePass,config
            )
            .then((res) => {
              // console.log("updatae",res);
              setTicketUpdateSuc(!ticketUpdateSuc);
             toast.success("Ticket updated successfully")
            });
        } catch (e) {
          toast.error("Error Updating Ticket")
          console.log(e);
        }
      };

      fetchcategory(ticketIssuePass);
    },

    enableReinitialize: true,
  });
  // Delete Comments
  const handleDeletecoments = async (id) =>{
    // console.log("id",id);
    try {
      await axios
        .delete(
          `${window.$domain}/api/resource/Support Ticket Comment/${id}`,
          config
        )
        .then((res) => {
          toast.success("Deleted successfully");
          setTicketUpdateSuc(!ticketUpdateSuc);
          setRefresh(!refresh)
        });
    } catch (e) {
      console.log(e);
      toast.error("Deletion Failed");
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className= {localStorage.role=="Best Diplomats Support Team" ? "flex justify-end mt-3 mb-1 gap-2 px-6":"flex justify-end mt-3 mb-1 gap-2"}>
   
     {loadingSkeleton ?<><Skeleton width={80} height={40} /> <Skeleton width={80} height={40} /></>:<><button
          onClick={() => {
            setOnFilters(true);
          }}
          className="ml-3 inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          <span>Filter</span>{" "}
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
            ></path>
          </svg>
        </button>

        <button
          onClick={() => {
            applyFilters.resetForm();
            setApiFilters(['["ticket_status","is","set"]']);
          }}
          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Clear Filter
        </button></>}
      </div>
      <div className="mt-1 py-1 divide-y ">
        <p className="felx justify-end text-right font-semibold text-gray-600">
          {loadingSkeleton ? (
            <Skeleton width={40} height={20} />
          ) : (
            <>Tickets: {ticketAccount} </>
          )}
        </p>
      </div>
      <div className="px-2 sm:px-2 lg:px-4">
        <div className="mt-4 flex flex-col">
          <div ref={tableRef}  className="-my-2 -mx-4 overflow-x-auto max-h-screen sm:-mx-6 lg:-mx-8">
            <div className={localStorage.role=="Best Diplomats Support Team" ? "inline-block min-w-full overflow-x-auto py-2 align-middle md:px-1 lg:px-10":"inline-block min-w-full overflow-x-auto py-2 align-middle md:px-1 lg:px-1"}>
             
              { isNotFoundName? <div className="h-screen flex justify-center flex-col items-center ">
                  <svg fill="none" className="w-40 h-40 text-gray-400" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path strokeLinecap="round" strokeLinejoin="round"d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
 </svg> <span className=" text-lg text-gray-400">  No Data Found</span> 
                </div>:  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"> <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-stone-100">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Issue Category
                      </th>
                      <th
                        scope="col"
                        className="px-3 whitespace-nowrap py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Ticket Status
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Creation
                      </th>
                    </tr>
                  </thead>
                  {loadingSkeleton?<tbody className="divide-y divide-gray-200 bg-white">
                   
                      <tr >
                        <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div
                            className="h-14 w-14 flex-shrink-0"
                           
                          >
                              <Skeleton borderRadius={40} width={50}  height={50}/>
                          </div>
                          <div className="ml-2">
                            <div
                            
                              className="font-medium cursor-pointer text-gray-900"
                            >
                               <Skeleton  width={90}  height={10}/>
                            </div>
                            <div className="text-gray-500">
                            <Skeleton  width={80}  height={10}/>
                            </div>
                          </div>
                        </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <Skeleton />
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">
                          <Skeleton />
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          
                              <Skeleton />
                           
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">
                          <Skeleton />
                          </div>
                        </td>
                      </tr>
                    
                  </tbody>: <tbody className="divide-y divide-gray-200 bg-white">
                   {onTicket?.slice((currentPage - 1) * 60, currentPage * 60).map((person) => (
                      <tr key={person.name}>
                        <td className="whitespace-nowrap py-4 pl-y pr-3 text-sm sm:pl-2">
                          <div className="flex items-center">
                        
                            {/* <div className="h-10 w-10 flex-shrink-0">
                              <img className="h-10 w-10 rounded-full" src={person.image} alt="" />
                            </div> */}
                            <div className="ml-4">
                              <div
                                className="font-medium text-gray-900 cursor-pointer"
                                onClick={() => {
                                  handleViewDetails(person);
                                  // console.log(person);
                                }}
                              >
                                {person.delegate_first_name}{" "}
                                {person.delegate_last_name}
                              </div>
                              <div className="text-gray-500">
                              {localStorage.role=="Operations Team"? null: person.delegate_email} 
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className=" px-3 py-4 text-sm text-gray-500">
                          {person.title == "" || person.title == null ? (
                            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                              Missing
                            </span>
                          ) : (
                            <div className="text-gray-900">{person.title}</div>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">
                            {person.issue_category}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.ticket_status == "" ||
                          person.ticket_status == null ? (
                            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                              Missing
                            </span>
                          ) : person?.ticket_status == "Open" ? (
                            <span className="inline-flex rounded-full bg-gray-200 px-2 text-xs font-semibold leading-5 text-Gray-800">
                              {person?.ticket_status}
                            </span>
                          ) : person?.ticket_status == "In Progress" ? (
                            <span className="inline-flex rounded-full bg-blue-400 px-2 text-xs font-semibold leading-5 text-white">
                              {person?.ticket_status}
                            </span>
                          ) : person?.ticket_status == "Resolved" ? (
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              {person?.ticket_status}
                            </span>
                          ) : (
                            <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                              {person?.ticket_status}
                            </span>
                          )}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">
                            {moment(person?.creation).format("LL")}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>}
                </table></div>}
              
            </div>
          </div>
        </div>
      </div>
      {isNotFoundName? null: <div className="flex items-center justify-between mt-7 bg-white px-3 py-3 sm:px-3  rounded-lg shadow">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                onClick={() => {
                  handlePaginationClick("Previous");
                }}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => {
                  handlePaginationClick("Next");
                }}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-start sm:justify-between sm:flex-col gap-y-1">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {(currentPage - 1) * 60 + 1}
                  </span>{" "}
                  to <span className="font-medium">{Math.min(((currentPage - 1) * 60 + 1) + 60, ticketAccount)}</span> of{" "}
                  <span className="font-medium">{ticketAccount}</span> results
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex flex-wrap gap-y-1 -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    onClick={() => {
                      handlePaginationClick("Previous");
                    }}
                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                  {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                  {pageNumbers?.map((number) => {
                    return (
                      <button
                        key={number}
                        className={
                          currentPage == number
                            ? "relative z-10 inline-flex items-center border border-purple-500 bg-purple-50 px-2 py-1.5 text-sm font-medium text-purple-600 focus:z-20"
                            : "relative hidden items-center border border-gray-300 bg-white px-2 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
                        }
                        onClick={() => handleClick(number)}
                      >
                        {number}
                      </button>
                    );
                  })}

                  <button
                    onClick={() => {
                      handlePaginationClick("Next");
                    }}
                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>}
      {/* Drawer for Filters */}
      <Transition.Root show={onFilters} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOnFilters}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form
                      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                      onSubmit={applyFilters.handleSubmit}
                    >
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-100 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg flex gap-2 font-medium text-gray-900">
                                <span>Filters</span>{" "}
                                <svg
                                  className="w-6 h-6 mt-1"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                                  ></path>
                                </svg>
                              </Dialog.Title>
                              {/* <p className="text-sm text-gray-500">
                                Get started..
                              </p> */}
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => {
                                  setOnFilters(false);
                                }}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Divider container */}

                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="delegate_first_name"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              name="delegate_first_name"
                              id="delegate_first_name"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.delegate_first_name}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="delegate_last_name"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              name="delegate_last_name"
                              id="delegate_last_name"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.delegate_last_name}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="delegate_email"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Email
                            </label>
                            <input
                              type="text"
                              name="delegate_email"
                              id="delegate_email"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.delegate_email}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="title"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Title
                            </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.title}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            ></input>
                          </div>

                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="event"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Event
                            </label>
                            <select
                              type="text"
                              name="event"
                              id="event"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.event}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            >
                              <option value="">Select</option>
                              {allEvents?.map((event)=>(
                                <option value={event.name}>{event.title}</option>
                              ))}
                            </select>
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="ticket_status"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Ticket Status
                            </label>
                            <select
                              type="text"
                              name="ticket_status"
                              id="ticket_status"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.ticket_status}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            >
                              <option value="">Select</option>
                              <option value="Open">Open</option>
                              <option value="In Progress">In Progress</option>
                              <option value="Resolved"> Resolved</option>
                              <option value="Closed">Closed</option>
                            </select>
                          </div>

                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="issue_category"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Issue Category
                            </label>
                            <select
                              type="text"
                              name="issue_category"
                              id="issue_category"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.issue_category}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            >
                              <option value="">Select</option>
                              <option value="Registration">Registration</option>
                              <option value="Payment">Payment</option>
                              <option value="Login">Login</option>
                              <option value="Diplomatic Stance">
                                Diplomatic Stance
                              </option>
                              <option value="Hotel Voucher">
                                Hotel Voucher
                              </option>
                              <option value="Invitation Acceptance">
                                Invitation Acceptance
                              </option>
                              <option value="Country Allocation">
                                Country Allocation
                              </option>
                              <option value="Flight Details Upload">
                                Flight Details Upload
                              </option>
                              <option value="Passport Details Upload">
                                Passport Details Upload
                              </option>
                              <option value="Visa Invitation Letter">
                                Visa Invitation Letter
                              </option>
                              <option value="Country Representative">
                                Country Representative
                              </option>
                              <option value="ID Card">ID Card</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex justify-end space-x-3">
                          <button
                            type="submit"
                            className="inline-flex color justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          >
                            Apply Filter
                          </button>
                          <button
                            onClick={() => {
                              applyFilters.resetForm();
                              setApiFilters(['["ticket_status","is","set"]']);
                            }}
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          >
                            Clear Filter
                          </button>
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            onClick={() => setOnFilters(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* details page */}

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Tickets
                          </Dialog.Title>
                          
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-blue-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="divide-y divide-gray-200">
                        <div className="pb-6">
                          <div className="h-24  sm:h-20 lg:h-28 bg-blue-500" />
                          <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                            <div className="mt-10 sm:ml-4 sm:flex-1">
                              <div>
                                <div className="flex items-center">
                                  <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                    {ticketDetail?.delegate_first_name +
                                      " " +
                                      ticketDetail?.delegate_last_name}
                                  </h3>
                                </div>
                           {localStorage.role=="Operations Team" ? null : <p className="text-sm text-gray-500">
                                  {ticketDetail?.delegate_email}
                                </p>}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="px-4 py-5 sm:px-0 sm:py-0">
                          <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                            <form
                              onSubmit={categoryUpdate.handleSubmit}
                              className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200"
                            >
                            <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Title
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {ticketDetail?.title == "" ||
                                  ticketDetail?.title == null ? (
                                    <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </span>
                                  ) : (
                                    <span>{ticketDetail?.title}</span>
                                  )}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Description
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {ticketDetail?.description == "" ||
                                  ticketDetail?.description == null ? (
                                    <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </span>
                                  ) : (
                                    <span>{ticketDetail?.description}</span>
                                  )}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Event
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {ticketDetail?.event == "" ||
                                  ticketDetail?.event == null ? (
                                    <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </span>
                                  ) : (
                                    <span>{allEvents?.find(eventE => eventE.name === ticketDetail?.event).title}</span>                                  )}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  File
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {ticketDetail?.attachment == "" ||
                                  ticketDetail?.attachment == null ? (
                                    <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </span>
                                  ) : (
                                    <a href={window.$domain+ticketDetail?.attachment} target="_blank" className="group  flex w-28 justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Open</a>
                                  )}
                                </dd>
                              </div>

                              <div className="sm:flex lg:items-center sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Issue Category
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  <label
                                    htmlFor="issue_category"
                                    className=" sr-only"
                                  ></label>
                                  <select
                                    type="text"
                                    name="issue_category"
                                    id="issue_category"
                                    onChange={categoryUpdate.handleChange}
                                    value={categoryUpdate.values.issue_category}
                                    className="mt-1 block w-60 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  >
                                    <option value="Registration">
                                      Registration
                                    </option>
                                    <option value="Payment">Payment</option>
                                    <option value="Login">Login</option>
                                    <option value="Diplomatic Stance">
                                      Diplomatic Stance
                                    </option>
                                    <option value="Hotel Voucher">
                                      Hotel Voucher
                                    </option>
                                    <option value="Invitation Acceptance">
                                      Invitation Acceptance
                                    </option>
                                    <option value="Country Allocation">
                                      Country Allocation
                                    </option>
                                    <option value="Flight Details Upload">
                                      Flight Details Upload
                                    </option>
                                    <option value="Passport Details Upload">
                                      Passport Details Upload
                                    </option>
                                    <option value="Visa Invitation Letter">
                                      Visa Invitation Letter
                                    </option>
                                    <option value="Country Representative">
                                    Country Representative
                                    </option>
                                    <option value="ID Card">ID Card</option>
                                    <option value="Other">Other</option>
                                    
                                  </select>
                                </dd>
                              </div>
                              
                              <div className="sm:flex lg:items-center sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Ticket Status
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  <label
                                    htmlFor="ticket_status"
                                    className=" sr-only"
                                  ></label>
                                  <select
                                    type="text"
                                    name="ticket_status"
                                    id="ticket_status"
                                    onChange={categoryUpdate.handleChange}
                                    value={categoryUpdate.values.ticket_status}
                                    className="mt-1 block w-60 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  >
                                    <option value="Open">Open</option>
                                    <option value="In Progress">
                                      In Progress
                                    </option>
                                    <option value="Resolved"> Resolved</option>
                                    <option value="Closed">Closed</option>
                                  </select>
                                </dd>
                              </div>

                              
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Creation
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {moment(ticketDetail?.creation).format(
                                    "LLLL"
                                  )}
                                </dd>
                              </div>
                              <div className="flex-shrink-0 flex justify-end border-t border-gray-200 px-3 py-3 sm:px-9">
                                <button
                                  type="submit"
                                  className="group  flex w-28 justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                >
                                  Update
                                </button>
                              </div>
                            </form>
                            <div className="flex justify-between py-3 px-6">
                             <span className="text-lg py-2 text-gray-700 font-semibold">Chat</span> 
                             <button onClick={()=>{setRefresh(!refresh)}} className="group  flex items-center  justify-center rounded-lg border border-transparent bg-blue-500 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">{refreshLoading ? <ArrowPathRoundedSquareIcon className="h-4 w-4 animate-spin"/>:<ArrowPathRoundedSquareIcon className="h-4 w-4"/>} </button>
                            </div>
                            <div
                              ref={toScroll}
                              id="toscroll"
                              className="sm:flex flex sm:px-0 sm:py-5 justify-center max-h-60 overflow-scroll"
                            >
                              <ul role="list" className="w-full px-4">
                                {chatThread?.map((activityItem) => (
                                  <li key={activityItem.name} className="py-4 ">
                                    <div className="flex space-x-3">
                                      <div className="flex-1 space-y-1">
                                        <div
                                          className={
                                            ticketDetail?.delegate_email ==
                                            activityItem?.author
                                              ? "flex items-center justify-start"
                                              : "flex items-center justify-end"
                                          }
                                        >
                                          <h3 className="text-sm flex  font-medium text-gray-400">
                                            {ticketDetail?.delegate_email ==
                                            activityItem?.author
                                              ? ticketDetail.delegate_first_name +
                                                " " +
                                                ticketDetail.delegate_last_name
                                              : localStorage.user_name}
                                          </h3>
                                        </div>
                                        <div
                                          className={
                                            ticketDetail?.delegate_email ==
                                            activityItem?.author
                                              ? "flex justify-start"
                                              : "flex justify-end"
                                          }
                                        >
                                          <p
                                            className={
                                              ticketDetail?.delegate_email ==
                                              activityItem?.author
                                                ? "text-sm flex justify-center p-2 bg-gray-500 rounded-t-lg rounded-l-lg text-white"
                                                : "text-sm bg-blue-500 rounded-lg text-white flex justify-center  p-2"
                                            }

                                            style={{ width: "max-content" }}
                                          >
                                            <span className="w-60 flex flex-col gap-1  whitespace-normal break-words">
                                              {activityItem.type ==
                                              "Comment" ? (
                                                activityItem.content
                                              ) : (
                                                <a
                                                  href={
                                                    "https://dash.bestdiplomats.org" +
                                                    activityItem.attachment
                                                  }
                                                  target="_blank"
                                                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                >
                                                  {" "}
                                                  Open
                                                  <svg
                                                    className="w-5 h-5 ml-1"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      strokeLinejoin="round"
                                                      strokeWidth="2"
                                                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                                    ></path>
                                                  </svg>
                                                </a>
                                              )}
                                            <span className="flex justify-end items-center"><TrashIcon className="h-6 w-6  text-red-500 cursor-pointer" onClick={()=>handleDeletecoments(activityItem.name)}/></span> 
                                            
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div className="sm:flex sm:px-3 sm:py-3">
                              <form
                                onSubmit={formik.handleSubmit}
                                className="flex w-full"
                              >
                                <label
                                  htmlFor="content"
                                  className="block text-sm font-medium sr-only text-blue-700"
                                >
                                  Search candidates
                                </label>
                                <textarea
                                  type="text"
                                  name="content"
                                  id="content"
                                  rows="3"
                                  onChange={formik.handleChange}
                                  value={formik.values.content}
                                  className="block w-full rounded-none rounded-l-md border-gray-300 pl-5 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                ></textarea>
                                <button
                                  type="submit"
                                  onClick={() => {
                                    setTimeout(() => {
                                      formik.resetForm();
                                    }, 1000);
                                  }}
                                  className="relative mt-10 h-10 -ml-px inline-flex items-center space-x-2 rounded-r-md border border-blue-300 bg-blue-500 px-6 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                >
                                  {submitDone ? (
                                    <svg
                                      fill="#87CEEB"
                                      viewBox="0 0 1792 1792"
                                      className="h-6 flex-no-shrink animate-spin"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z" />
                                    </svg>
                                  ) : (
                                    <PaperAirplaneIcon className="text-white h-6 w-6" />
                                  )}
                                </button>

                                <label
                                  for="formFileSm"
                                  className="ml-2 flex items-center mt-10 h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                >
                                  <LinkIcon className="w-6 h-6" />
                                </label>
                                <input
                                  className= " sr-only "
                                  id="formFileSm"
                                  type="file"
                                  onClick={formik.resetForm}
                                  onChange={(e) => {
                                    
                                    uploadFlightDocument(e);
                                  }}
                                ></input>
                              </form>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default Ticket;
