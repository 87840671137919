import React, { useEffect } from 'react'
import { useState } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";
import { useParams, Link } from "react-router-dom";
// import CheckInAllTypeRegistration from './CheckInAllTypeRegistration';
import { ListBulletIcon } from '@heroicons/react/24/outline';
// import LocalTeamCheckin from './LocalTeamCheckin';
import CountryRep from './CountryRep';
import RepresentativeAnnouncementCenter from './Representative Announcement Center/RepresentativeAnnouncementCenter';
import RepresentativeChat from './Representative Chat/RepresentativeChat';
import RepresentativeBadge from './Representative Badges/RepresentativeBadge';
import Task from './Task/Task';
import AllTask from './Task/AllTask';
import AllSubmission from './Task/AllSubmission';
import CRModule from './CR Module/CRModule';
import PointsAudit from'./CR Module/PointsAudit';

const RepresentativeNavbar = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    const { eventID } = useParams();
    const CheckinNavbar = [
        {
          name: "Representative",
          href: `representative/${eventID}`,
          icon: ListBulletIcon,
          current: true,
        },
        {
          name: "Announcement",
          href: `rep-Announcement/${eventID}`,
          icon: ListBulletIcon,
          current: false,
        },
        {
          name: "Chat",
          href: `rep-Chat/${eventID}`,
          icon: ListBulletIcon,
          current: false,
        },
        {
          name: "Badge",
          href: `rep-Badge/${eventID}`,
          icon: ListBulletIcon,
          current: false,
        },
        {
          name: "Task",
          href: `rep-task/${eventID}`,
          icon: ListBulletIcon,
          current: false,
        },
        
       
      ];
      function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
      }
      
    const [
        currentRepresentativeNavigation,
        setcurrentRepresentativeNavigation,
      ] = useState(CheckinNavbar);
      
  const handleClickRepresentativeNavigation = (index, setcurrentRepresentativeNavigation) => {
    const updatedNavigation = currentRepresentativeNavigation.map((item, i) => {
      if (i === index) {
        return { ...item, current: true };
      } else {
        return { ...item, current: false };
      }
    });
    setcurrentRepresentativeNavigation(updatedNavigation);
  };
  
  useEffect(() => {
    const path = window.location.pathname;
    const updatedNavigation = currentRepresentativeNavigation.map((item) => {
      if (path.includes(item.href)) {
        return { ...item, current: true };
      } else {
        return { ...item, current: false };
      }
    });
    const hasCurrentItem = updatedNavigation.some((item) => item.current);
    if (!hasCurrentItem) {
      updatedNavigation[0].current = true;
    }

    setcurrentRepresentativeNavigation(updatedNavigation);
  }, []);
  const [dataFromChild, setDataFromChild] = useState(null);

  // Callback function to receive data from the child
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
    // console.log("data",data);
  };
  return (
    <div classNames="pt-4">
    <nav className=" px-2 h-12  banner-back rounded-md flex items-center justify-between ">
      <div className=" px-2 gap-2  rounded-md flex items-center  ">
      {currentRepresentativeNavigation.map((item, index) => (
        <Link
          key={item.name}
          to={item.href}
          className={classNames(
            item.current
              ? "bg-blue-700 border-blue-700 text-white hover:bg-blue-600 hover:text-white"
              : "border-transparent text-white hover:bg-blue-600 hover:text-white",
            "group flex items-center rounded-md px-3 py-2 text-sm font-medium"
          )}
          onClick={() => handleClickRepresentativeNavigation(index, setcurrentRepresentativeNavigation)}
        >
          {item.name}
        </Link>
      ))}
      </div>
   
    </nav>
    <Routes>
      <Route path="representative/:eventID" element={<CountryRep  />} />
      <Route path="rep-Announcement/:eventID" element={<RepresentativeAnnouncementCenter />} />
      <Route path="rep-Chat/:eventID" element={<RepresentativeChat />} />
      <Route path="rep-Badge/:eventID" element={<RepresentativeBadge />} />
      <Route path="rep-task/:eventID" element={<Task />} />
      <Route path="rep-task/:eventID/rep-alltask/:eventID" element={<AllTask sendDataToParent={handleDataFromChild} />} />
      <Route path="rep-task/:eventID/rep-alltask/:eventID/rep-all-submission/:eventID" element={<AllSubmission passData={dataFromChild}/>} />
      <Route path="representative/:eventID/cr-module/:ID" element={<CRModule/>} />
      <Route path="representative/:eventID/cr-module/:ID/points-audit/:ID" element={<PointsAudit/>} />
    </Routes>
  </div>)}
export default RepresentativeNavbar