import React, { useRef } from "react";
import ReactPlayer from "react-player";
import { useState, useEffect } from "react";
import axios from "axios";
import { Fragment } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  ArrowTopRightOnSquareIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Skeleton from "react-loading-skeleton";
import { EllipsisVerticalIcon, CheckIcon,ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useFormik } from "formik";
import { Toaster, toast } from 'sonner'
import PrivateImage from '../PrivateImage/PrivateImage';
import { useParams } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Upgrades = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Configrations
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const { eventID } = useParams();
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [ambdata, setAmbData] = useState([]);
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [submitDone, setSubmitDone] = useState(false);
  const [instagramprofile, setInstagramProfile] = useState();
  const [facebookprofile, setFacebookProfile] = useState();
  const [twitterprofile, setTwitterProfile] = useState();
  const [linkedinprofile, setLinkedinProfile] = useState();
  const [businessperson, setBusinessPerson] = useState(false);
  const [student, setStudent] = useState(false);
  const [employee, setEmployee] = useState(false);
  const [totalUpgrades, setTotalUpgrades] = useState();
  const [isNotFoundAmb,setIsNotFoundAmb ] = useState(false);
 

  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setpageNumber] = useState([]);
  const tableRef = useRef(null);
  const [inviteFilter, setInviteFilter] = useState([]);
  const [bio, setBio] = useState(true);

  useEffect(() => {
    setLoadingSkeleton(true)
    const fetchUpgradesData = async () => {
      try {
        await axios
          .get(`${window.$domain}/api/method/get-upgrades?filters={"event":"${eventID}"}`,config)
          .then((response) => {
            setAmbData(response.data.data);
            // console.log("upgrades",response.data.data);
            setpageNumber([]);
            for (
              let i = 1;
              i <= Math.ceil(response.data.data.length / 40);
              i++
            ) {
              setpageNumber((prevState) => [...prevState, i]);
              // console.log(pageNumbers);
            }
            // console.log(eventID);
            setTotalUpgrades(response.data.data.length);
            setIsNotFoundAmb(false);
            setLoadingSkeleton(false)
            if(response.data.data.length==[]){
              setLoadingSkeleton(true)
              toast.error('No Data Found')
              setIsNotFoundAmb(true);
            }
          });
      } catch (e) {
        console.log(e);
        if (e.code == "ERR_NETWORK") {
          toast.error('No Data Found')
          setIsNotFoundAmb(true);
          
        }
      }
    };
    fetchUpgradesData();
  }, [submitDone,inviteFilter]);
  const scrollToTop = () => {
    tableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const handleClick = (number) => {
    setCurrentPage(number);
    scroll();
    scrollToTop()
  };
  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handlePaginationClick = (event) => {
    if (event == "Previous") {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        scroll();
        scrollToTop()
      }
    } else if (event == "Next") {
      if (currentPage < pageNumbers.length) {
        setCurrentPage(currentPage + 1);
        scroll();
        scrollToTop()
      }
    }
  };

  const [single, setSingleAmbs] = useState([]);



  return (
    <>
        
        <div className="py-1  lg:mr-2 flex items-center justify-end sm:mt-0 sm:ml-1 sm:flex-none">
<Toaster position='bottom-left' richColors />
          <p className=" font-semibold flex   items-center text-gray-700">
          { loadingSkeleton ?<Skeleton width={40} height={20} /> :<> Upgrades: {totalUpgrades} </> }
          </p>
        </div>
    <div className="px-2 sm:px-4   lg:px-4">
       
    
      <div className=" flex flex-col">
        

        <div ref={tableRef}  className="my-2 -mx-4 overflow-x-auto max-h-screen sm:-mx-4 lg:-mx-4">
          <div ref={tableRef} className="inline-block overflow-auto min-w-full py-1 align-middle md:px-3 lg:px-3">
          
            { isNotFoundAmb ? <div className="h-screen flex justify-center flex-col items-center ">
                  <svg fill="none" className="w-40 h-40 text-gray-400" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path strokeLinecap="round" strokeLinejoin="round"d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
 </svg> <span className=" text-lg text-gray-400">  No Data Found</span> 
                </div>:   <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"> <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-stone-100">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Product Name
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Payment Status
                    </th>
                  
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                     Room Status
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                     Purchase Type
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                     Amount
                    </th>
                  </tr>
                </thead>
                {  loadingSkeleton? <tbody className="divide-y divide-gray-200 bg-white">
                   
                   <tr >
                     <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-6">
                     
                       <div className="flex items-center">
                          <div
                            className="h-14 w-14 flex-shrink-0"
                           
                          >
                              <Skeleton borderRadius={40} width={50}  height={50}/>
                          </div>
                          <div className="ml-2">
                            <div
                            
                              className="font-medium cursor-pointer text-gray-900"
                            >
                               <Skeleton  width={90}  height={10}/>
                            </div>
                           
                          </div>
                        </div>
                     </td>
                    
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td> 
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>

                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>
                   </tr>
                 
               </tbody>: <tbody className="divide-y divide-gray-200 bg-white">
                  {ambdata?.slice((currentPage - 1) * 40, currentPage * 40).map((amb) => (
                    <tr key={amb.delegate_profile?.name}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div
                            className="h-14 w-14 flex-shrink-0"
                          
                          >
                            <PrivateImage imageUrl={amb.user.user_image} className={"h-14 w-14  rounded-full"}/> 
                           
                          </div>
                          <div className="ml-4">
                            <div
                              
                              className="font-medium  text-gray-900"
                            >
                              {amb.user.first_name} {amb.user.last_name}
                            </div>
                            {/* <div className="text-gray-500">
                              {amb.user.email}
                            </div> */}
                          </div>
                        </div>
                      </td>
                     
                      {amb.product.product_name == null||amb.product.product_name == "" ? (
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                            {" "}
                            Missing
                          </div>
                          <div className="text-gray-500"></div>
                        </td>
                      ) : (
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">
                            {" "}
                            {amb.product.product_name}
                           
                          </div>
                          <div className="text-gray-500"></div>
                        </td>
                      )}
                      
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {amb.payment_info.status == "" ||
                        amb.payment_info.status== null ? (
                          <div className="mt-1 sm:col-span-2 sm:mt-0 sm:ml-6 inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                            Missing
                          </div>
                        ) : (
                          <div className="text-gray-900">
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            {amb.payment_info.status}
                          </span>
                            
                          </div>
                        )}{" "}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {amb.event_registration.room_status == "" ||
                        amb.event_registration.room_status== null ? (
                          <div className="mt-1 sm:col-span-2 sm:mt-0 sm:ml-6 inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                            Missing
                          </div>
                        ) : (
                          <div className="text-gray-900">
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            {amb.event_registration.room_status}
                          </span>
                            
                          </div>
                        )}{" "}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {amb.payment_info.purchase_type == "" ||
                        amb.payment_info.purchase_type== null ? (
                          <div className="mt-1 sm:col-span-2 sm:mt-0 sm:ml-6 inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                            Missing
                          </div>
                        ) : (
                          <div className="text-gray-900">
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            {amb.payment_info.purchase_type}
                          </span>
                            
                          </div>
                        )}{" "}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {amb.payment_info.amount == "" ||
                        amb.payment_info.amount== null ? (
                          <div className="mt-1 sm:col-span-2 sm:mt-0 sm:ml-6 inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                            Missing
                          </div>
                        ) : (
                          <div className="text-gray-900">
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            ${amb.payment_info.amount / 100}
                          </span>
                            
                          </div>
                        )}{" "}
                      </td>

                    
                    </tr>
                  ))}
                </tbody>}
              </table>  </div>}
          
          </div>
        </div>
      </div>
     
      {isNotFoundAmb ? null: <div className="flex items-center justify-between mt-7 bg-white px-4 py-3 sm:px-6  rounded-lg shadow">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                onClick={() => {
                  handlePaginationClick("Previous");
                }}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => {
                  handlePaginationClick("Next");
                }}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {(currentPage - 1) * 40 + 1}
                  </span>{" "}
                  to <span className="font-medium">{currentPage * (totalUpgrades % 40)}</span> of{" "}
                  <span className="font-medium">{totalUpgrades}</span> results
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    onClick={() => {
                      handlePaginationClick("Previous");
                    }}
                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                  {pageNumbers?.map((number) => {
                    return (
                      <button
                        key={number}
                        className={
                          currentPage == number
                            ? "relative z-10 inline-flex items-center border border-purple-500 bg-purple-50 px-4 py-2 text-sm font-medium text-purple-600 focus:z-20"
                            : "relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
                        }
                        onClick={() => handleClick(number)}
                      >
                        {number}
                      </button>
                    );
                  })}

                  <button
                    onClick={() => {
                      handlePaginationClick("Next");
                    }}
                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>}

    </div>
    </>
  );
};


export default Upgrades